<template>
<v-dialog v-model="show" max-width="420px">
  <v-card v-if="member">
    <v-card-title class="display-1 font-weight-bold py-8 justify-center">
      {{ member.name }}
    </v-card-title>
    <v-card-text class="pb-8 text-center">
      <v-btn v-for="(l, i) in links()" :key="i" :title="l.name" @click="navigate(l)" fab class="ma-2">
        <img :src="`/img/icon/${l.icon}.png`">
      </v-btn>
    </v-card-text>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  props: {
    value: { type: Boolean, required: true },
    member: { type: Object, required: true }
  },
  computed: {
    show: {
      get() { return this.value },
      set(value) { this.$emit('input', value) }
    }
  },
  methods: {
    links() {
      const { name, realm } = this.member
      if (name && realm) {
        return [
          { name: 'WoW Armory',     icon: 'icon-armory',        url: `https://worldofwarcraft.com/en-gb/character/eu/${realm}/${name}` },
          { name: 'Raider.IO',      icon: 'icon-raiderio',      url: `https://raider.io/characters/eu/${realm}/${name}` },
          { name: 'Warcraft Logs',  icon: 'icon-warcraftlogs',  url: `https://www.warcraftlogs.com/character/eu/${realm}/${name}` },
          { name: 'Raidbots',       icon: 'icon-raidbots',      url: `https://www.raidbots.com/simbot/quick?region=eu&realm=${realm}&name=${name}` },
          { name: 'Польоти',        icon: 'icon-flying',         url: `https://www.wowhead.com/flying#eu-${realm}-${name.toLowerCase()}` }
        ]
      } else {
        return []
      }
    },
    navigate: function (link) {
      window.open(link.url, '_blank')
      this.$gtag.event('member_link_click', { member: this.member.name, link: link.name })
    }
  }
}
</script>

<style scoped>
</style>
