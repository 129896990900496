<template>
  <div class="list">
    <div v-for="(r, i) in recipes" :key="i" class="item">
      <div class="art-bar">
        <div v-if="r.rank" class="mb-1 d-flex justify-center align-center" title="Ранг рецепту">
          <v-icon v-for="j in r.rank" :key="j" class="rank-star">mdi-star</v-icon>
        </div>
        <div v-if="r.slLegendaryRank" class="mb-1 d-flex justify-center align-center" title="Рівень предмету легендарного рецепту Тінеземель">
          <v-chip label class="sl-leg-ilvl">{{ [ 190, 210, 225, 235 ][r.slLegendaryRank - 1] }}</v-chip>
        </div>
        <a v-if="r.item" :href="`https://www.wowhead.com/item=${r.item.id}`" target="_blank" class="d-flex">
          <img class="image" :src="r.media || r.item.media" alt="">
        </a>
        <img v-else class="image" :src="r.media || r.item.media" alt="">
        <div v-if="r.item && r.item.min > 1" class="quantity">
          <span>+{{ r.item.min }}</span>
          <span v-if="r.item.max != r.item.min">-{{ r.item.max }}</span>
        </div>
      </div>
      <div class="content-bar">
        <div class="name">{{ r.name }}</div>
        <div class="prof">{{ prof(r) | capitalize }}</div>
        <div class="cat">{{ cat(r) | capitalize }}</div>
        <div class="footer-bar">
          <v-chip label :disabled="r.crafters.length == 0" @click="$emit('click-crafters', r)">
            <v-icon left>mdi-account-hard-hat</v-icon> <big>{{ r.crafters.length }}</big>
          </v-chip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recipes: { type: Array, required: true },
    professions: { type: Array, required: true }
  },
  data() {
    return {
      tiersMap: {},
    }
  },
  created: function () {
    this.tiersMap = this.professions.reduce((a, c) => {
      for (const t of c.tiers) {
        a[t.id] = { prof: c, tier: t }
      }
      return a
    }, {})
  },
  methods: {
    prof: function (recipe) {
      const { tier } = this.tiersMap[recipe.tier]
      return tier.loc?.it || tier.name
    },
    cat: function (recipe) {
      const { tier } = this.tiersMap[recipe.tier]
      const cat = tier.cats[recipe.cat]
      return cat.loc?.it || cat.name
    }
  }
}
</script>

<style scoped>

.list {
  margin: 0 -10px;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
}

.item {
  position: relative;
  margin: 10px;
  padding: 26px;
  display: flex;
  width: 100%;
  border-radius: 12px;
  background-color: #eee6df;
  transition: .25s background-color ease-in;
}

.item:hover {
  background-color: #fb8455;
}

.item .art-bar {
  position: absolute;
  right: 26px;
  bottom: 26px;
  display: flex;
  flex-direction: column;
}

.item .content-bar {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.item .image {
  width: 60px; /* 56px + 2 * border radius */
  height: 60px; /* 56px + 2 * border radius */
  border: 2px solid #1e1e1e;
  border-radius: 50%;
  border-top-left-radius: 0;
}

.item .quantity {
  position: absolute;
  left: -8px;
  bottom: -12px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  border: 2px solid #1e1e1e;
  border-radius: 50%;
  background-color: #fa7d4f;
  pointer-events: none;
}

.item .rank-star {
  margin-left: -.05em;
  margin-right: -.05em;
  font-size: 18px;
}

.item .sl-leg-ilvl {
  padding: 0 .65em;
  height: auto;
  line-height: 1.8;
  font-size: 14px;
  font-weight: 600;
  background: transparent;
}

.item .name {
  margin-bottom: .4em;
  font-size: 185%;
  font-weight: bold;
  line-height: 1.2;
}

.item .prof,
.item .cat {
  font-size: 115%;
}

.item .footer-bar {
  margin-top: 1em;
  flex: 1;
  display: flex;
  align-items: flex-end;
}

a:hover {
  opacity: 1;
}

@media (min-width: 740px) {
  .item {
    width: 372px;
    min-height: 252px;
  }
}

</style>
