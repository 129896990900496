<template>
<div class="app-container with-content-offset-bottom pt-0">
  <RosterNav :loading="state == 'loading'" :failure="state == 'failure'" :backgroundLoading="resultsLoaderTimeoutId > 0" />
  <div v-if="state == 'ready'">
    <div class="toolbar">
      <v-btn icon large class="toolbar-button" @click="clickHotRecipeList">
        <v-icon>mdi-crown</v-icon>
      </v-btn>
      <v-text-field v-model="filterText"
        @change="searchTextChange" @click:clear="clear"
        :hint="status" :error-messages="error ? [ error ] : []"
        autofocus clearable outlined persistent-hint label="Пошук"></v-text-field>
    </div>
    <RecipeList class="mt-6" :recipes="displayResults" :professions="professions" @click-crafters="clickCrafters" />
    <v-dialog v-model="popupRecipeModel" max-width="640px">
      <v-card v-if="popupRecipe">
        <v-card-title class="popup-title py-8 justify-center">{{ popupRecipe.name }}</v-card-title>
        <v-card-text class="pb-8 text-center">
          <div class="text-h5 text-uppercase pb-4">{{ crafterGroupText(popupRecipe) || 'майстри' }}</div>
          <RosterList v-if="popupMembers.length > 0" class="list-limit-height" :members="popupMembers" :classes="classes" clickable @click-member="clickMember" />
          <span v-else>Хвилинку...</span>
          <div v-if="popupMembers.length > 1" class="mt-4 font-italic color-grey">
            Персонажі впорядковані за часом останньої активності.
          </div>
          <Member v-model="popupMemberModel" :member="popupMember" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="popupRecipeListModel" max-width="800px">
      <v-card>
        <v-card-title class="popup-title pt-8 justify-center">Легендарні рецепти Тінеземель</v-card-title>
        <v-card-text class="pb-8 text-center">
          <div class="list-limit-height">
            <div v-for="(group, i) of slLegendaries" :key="i">
              <div class="text-h5 text-uppercase py-4">{{ group.group }}</div>
              <div class="d-flex justify-center flex-wrap">
                <div v-for="(item, j) of group.items" :key="j">
                  <v-btn small text :title="item.name" @click="clickSlLegendaryRecipe(item)">{{ item.slot }}</v-btn>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</div>
</template>

<script>

import { apiFetch } from '@/utils'
import Member from '@/components/Member.vue'
import RosterNav from '@/components/RosterNav'
import RecipeList from '@/components/RecipeList'
import RosterList from '@/components/RosterList'

export default {
  components: { Member, RosterNav, RecipeList, RosterList },
  data() {
    return {
      state: '?', // 'loading', 'failure', 'ready'
      filterText: '',
      status: '',
      error: '',
      resultsLoaderTimeoutId: 0,
      professions: [],
      slLegendaries: [],
      slLegendariesMap: {},
      results: [],
      cursor: 0,
      text: '',
      popupRecipeModel: false,
      popupRecipe: {},
      popupMembers: [],
      popupMemberModel: false,
      popupMember: {},
      classes: [],
      members: [],
      popupRecipeListModel: false,
    }
  },
  created: function () {
    this.setPageMetadata('Ремесло')
    this.state = 'loading'
    Promise.all([
      apiFetch('/professions'),
      apiFetch('/shadowlands-legendary-recipes'),
      apiFetch('/classes'),
      apiFetch('/roster'),
    ]).then(([ professions, slLegendaries, classes, members ]) => {
      this.professions = professions
      this.slLegendaries = slLegendaries
      this.slLegendariesMap = slLegendaries.reduce((a, c) => {
        c.items.forEach(it => it.ranks.forEach(id => a[id] = it))
        return a
      }, {})
      this.classes = classes
      this.members = members
      this.state = 'ready'
    }).catch(() => this.state = 'failure')

    this.clear()
    this.routeUpdated()
  },
  destroyed: function () {
    this.stopResultsLoader()
  },
  watch: {
    $route: 'routeUpdated'
  },
  computed: {
    displayResults: function () {
      return this.results.map(e => {
        if (this.slLegendariesMap[e.id]) {
          e.slLegendaryRank = this.slLegendariesMap[e.id].ranks.indexOf(e.id) + 1
        }
        return e
      })
    }
  },
  methods: {
    routeUpdated() {
      if (this.$route.query.text) {
        const { text } = this.$route.query
        this.filterText = text
        this.searchTextChange(text)
      }
    },
    searchTextChange(text) {
      text = (text || '').trim()
      if (this.text == text) {
        return
      }

      this.stopResultsLoader()
      this.error = ''

      if (text) {
        if (text.length >= 3) {
          this.text = text
          this.cursor = 0
          this.results = []
          this.loadResults()
          this.$gtag.event('recipes_search_text', { text })
          if (this.$route.query.text != text) {
            this.$router.push({ path: this.$route.path, query: { text } })
          }
        } else {
          this.error = 'Мінімум три літери'
        }
      }
    },
    loadResults: function () {
      const text = encodeURIComponent(this.text.replaceAll(/[[\]]/g, '')) // ignore square brackets ([]) for easier copy-paste from the game into search box
      apiFetch(`/search-recipe?text=${text}&cursor=${this.cursor}`).then(data => {
        const { results, cursor } = data
        this.results.push(...results)
        this.cursor = cursor
        this.status = `Результатів пошуку "${this.text}": ${this.results.length}${cursor > 0 ? '+' : ''}`
        this.resultsLoaderTimeoutId = cursor > 0
          ? setTimeout(this.loadResults, 500)
          : 0
      })
    },
    stopResultsLoader: function () {
      if (this.resultsLoaderTimeoutId > 0) {
        clearTimeout(this.resultsLoaderTimeoutId)
        this.resultsLoaderTimeoutId = 0
      }
    },
    clear: function () {
      this.text = ''
      this.cursor = 0,
      this.results = []
      this.status = 'Введіть частину назви...'
      this.error = ''
    },
    clickCrafters: function (recipe) {
      this.popupRecipeModel = true
      this.popupRecipe = recipe
      this.popupMembers = []

      Promise.all(
        recipe.crafters.map(slug => apiFetch(`/char/${slug}`))
      ).then(profiles => {
        this.popupMembers = recipe.crafters.map((slug, i) => {
          const member = this.members.find(m => `${m.name}-${m.realm}`.toLowerCase() == slug)
          member.avatar = profiles[i].avatar
          member.inset = profiles[i].inset
          member.loadingStatus = 'done'
          return member
        })
      })

      this.$gtag.event('recipes_crafter_list_click', { recipe: recipe.name })
    },
    crafterGroupText: function (recipe) {
      const prof = this.professions.find(p => p.tiers.find(t => t.id == recipe.tier))
      return prof?.loc?.they
    },
    clickHotRecipeList: function () {
      this.popupRecipeListModel = true
      this.$gtag.event('recipes_hot_list_click')
    },
    clickSlLegendaryRecipe: function (item) {
      this.popupRecipeListModel = false
      this.filterText = item.name
      this.searchTextChange(item.name)
    },
    clickMember: function (member) {
      this.popupMember = member
      this.popupMemberModel = true
      this.$gtag.event('recipes_member_click', { member: member.name })
    }
  }
}

</script>

<style scoped>

.toolbar {
  margin: 0 auto;
  display: flex;
}

.toolbar-button {
  margin-top: 6px;
  margin-right: 8px;
}

.popup-title {
  font-size: 175% !important;
  font-weight: bold !important;
  word-break: normal;
  text-align: center;
}

.list-limit-height {
  max-height: 52vh;
  overflow-y: auto;
}

@media (min-width: 720px) {
  .toolbar {
    width: 60%;
  }
}

</style>
